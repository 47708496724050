import React from 'react';
import { TimeToEnd } from '../types';

interface RaspuCountdownTimerProps {
  readonly timeLeft: TimeToEnd
}

const RaspuCountdownTimer: React.FC<RaspuCountdownTimerProps> = ({ timeLeft }) => {

  return (
    <div className='rasputin-font countdown-text'>
      {Object.entries(timeLeft)
        .filter(([key]) => key !== 'isTimeUp')
        .map(([interval, value], index, array) => (
          <span key={interval}>
            {String(value).padStart(2, '0')}{index < array.length - 1 ? ':' : ''}
          </span>
        ))}
    </div>
  );
};

export default RaspuCountdownTimer;
