import React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import RasputinTitle from './components/RasputinTitle'
import SongList from './components/SongList'
import SongPage from './components/SongPage'
import LoadingIcon from './components/LoadingIcon'
import BottomCarousel from './components/BottomCarousel'
import categoryService from './services/category'
import songService from './services/song'
import { Category } from '../../interface-models/Category'
import { SongListItem } from '../../interface-models/SongListItem'
import './antd.css'
import './App.css'
import { SongSearchResult } from './interfaces/SongSearchResult'
import SongListSearch from './components/SongListSearch'
import SongListSearchView from './components/SongListSearchView'
import SearchIcon from './components/SearchIcon'
import songListSelectionHelper from './utils/songListSelectionHelper'
import { Button } from 'antd'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import Footer from './components/Footer'

interface SongMatchParams {
  readonly songId: string
}

interface PreviousAndNextSongId {
  readonly currentSong: SongListItem | null
  readonly previousSongId: number | null
  readonly nextSongId: number | null
}

const TITLE = 'Rasputin'

const App: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(true)
  const [categories, setCategories] = React.useState<Category[]>([])
  const [songList, setSongList] = React.useState<SongListItem[]>([]) // Songs are always ordered!
  const [songSearch, setSongSearch] = React.useState<SongSearchResult | null>(null)
  const [searchEmpty, setSearchEmpty] = React.useState<boolean>(songSearch === null)
  const [searching, setSearching] = React.useState<boolean>(false)
  const [searchValue, setSearchValue] = React.useState<string>(songSearch ? songSearch.pattern : '')
  const history = useHistory()

  React.useEffect(() => {
    const loadDataFromServer = async () => {
      setCategories(await categoryService.getCategories())
      setSongList(await songService.getSongList())
      setLoading(false)
    }
    loadDataFromServer()
  }, [])

  const setAppHeight = () => document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`)
  window.addEventListener('resize', setAppHeight)
  setAppHeight()

  const songIdToNumber = (songIdFromRoute: string): number | null => {
    const songIdNum = Number(songIdFromRoute)
    if (!Number.isNaN(songIdNum) && songIdNum.toString() === songIdFromRoute) {
      return songIdNum
    }
    return null
  }

  const findPreviousAndNextSongIds = (songId: number | null): PreviousAndNextSongId => {
    if (songId) {
      const currentSongIndex = songList.findIndex(song => song.id === songId)
      if (currentSongIndex !== -1) {
        const previousSong = songList[currentSongIndex - 1]
        const nextSong = songList[currentSongIndex + 1]
        return {
          currentSong: songList[currentSongIndex],
          previousSongId: previousSong ? previousSong.id : null,
          nextSongId: nextSong ? nextSong.id : null
        }
      }
    }

    return {
      currentSong: null,
      previousSongId: null,
      nextSongId: null
    }
  }

  const getContentComponent = (isSongMatch: boolean) => {
    if (loading) {
      return <LoadingIcon />
    }

    if (isSongMatch) {
      return (
        <>
        {!searching && !loading && !songSearch && 
          <SongPage
          songId={songId}
          songCategory={songCategory}
          songFound={previousAndNextSongIds.currentSong !== null}
        />}
        </>
      )
    } else {
      return (
        <SongList
          categories={categories}
          songList={songList}
          searchEmpty={searchEmpty}
        />
      )
    }
  }

  const resetSongSearch = () => {
    setSongSearch(null)
  }
  
  const handleSongClick = (songId: number) => {
    songListSelectionHelper.setLastSelectedSong(songId)
    history.push(`/${songId}`)
    setSearchEmpty(true)
    setSongSearch(null)
    setSearchValue('')
  }

  const previousArrowEnabled = (): boolean => !loading
    ? previousAndNextSongIds.previousSongId !== null
    : loading

  const nextArrowEnabled = (): boolean => !loading
    ? previousAndNextSongIds.nextSongId !== null
    : loading
  
  const handlePreviousArrowPress = () => {
    if (previousAndNextSongIds.previousSongId) {
      history.push(`/${previousAndNextSongIds.previousSongId}`)
    }
  }

  const handleNextArrowPress = () => {
    if (previousAndNextSongIds.nextSongId) {
      history.push(`/${previousAndNextSongIds.nextSongId}`)
    }
  }

  // Check for song ID
  const songMatch = useRouteMatch<SongMatchParams>('/:songId')
  const songId = songMatch ? songIdToNumber(songMatch.params.songId) : null
  const previousAndNextSongIds: PreviousAndNextSongId = findPreviousAndNextSongIds(songId)

  // Check song category
  const category = previousAndNextSongIds.currentSong
    ? categories.find(category => category.id === previousAndNextSongIds.currentSong?.category)
    : null
  const songCategory = category ? category.name : null

  // Set document title
  document.title = previousAndNextSongIds.currentSong
    ? `${TITLE}: ${previousAndNextSongIds.currentSong.id} ${previousAndNextSongIds.currentSong.name}`
    : TITLE

  return (
    <div className='background' style={{ backgroundImage: 'url(/bg.png)' }}>
      <div className='container-wrapper'>
        <RasputinTitle />
        <img className='stripes' src="/stripes.svg" alt="stripes" />
        <div className='paper'>
          <div className='search-container'>
              <SongListSearch 
                songSearch={songSearch} 
                setSongSearch={setSongSearch} 
                setSearchEmpty={empty => {
                  setSearchEmpty(empty)
                  if (empty) {
                    resetSongSearch()
                  }
                }} 
                setSearching={setSearching}
                searchChanged={resetSongSearch}
                filterUnexistingSong={match => match.filter(s => songList.find(ss => ss.id === s.id))}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
             <div className='button-container'>
              <Button
                  size='large'
                  icon={<ArrowLeftOutlined />}
                  disabled={!previousArrowEnabled()}
                  onClick={handlePreviousArrowPress}
                />
                <Button
                  size='large'
                  icon={<ArrowRightOutlined />}
                  disabled={!nextArrowEnabled()}
                  onClick={handleNextArrowPress}
                />
             </div>
            </div>
          <div className='paper-content'>
            {searching && <LoadingIcon />}
            {!searchEmpty && !searching && !songSearch && <SearchIcon />}
            {
              !searchEmpty && !searching && songSearch &&
              <SongListSearchView
                searchResult={songSearch} 
                handleClick={handleSongClick}
              />
            }
            {(searchEmpty || searching || songSearch) && getContentComponent(songMatch !== null)}
          </div>
        </div>

        <BottomCarousel />
      </div>
      <Footer />
    </div>
  )
}

export default App
