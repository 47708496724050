import { InstagramOutlined } from '@ant-design/icons'
import React from 'react'

const Footer = () => {
  return (
    <div className='footer-container'>
      <a href='https://perinneseura.fi' style={{ display: 'flex', alignItems: 'center', color: 'white' }}>
        <img className='footer-logo' src="/pseura.svg" alt="Teekkarien PerinneSeura" />
        <h4 className='footer-text' style={{ fontWeight: '600' }}>Teekkarien PerinneSeura ry</h4>
      </a>
      <a className='footer-ig' href="https://instagram.com/jaynakisatre">
        <InstagramOutlined />
      </a>
    </div>
  )
}

export default Footer