import React from 'react'
import { Collapse, List } from 'antd'
import { CaretRightFilled } from '@ant-design/icons'
import { Category } from 'raspu-web-interface-models/Category'
import songListSelectionHelper from '../utils/songListSelectionHelper'
import About from './About'
import { SongListItem } from 'raspu-web-interface-models/SongListItem'
import SongListRow from './SongListRow'
import { SongClickHandler } from '../interfaces/SongClickHandler'
import RaspuPromo from './RaspuIsComing'

interface SongListCategoryViewProps extends SongClickHandler {
  readonly categories: Category[]
  readonly songList: SongListItem[]
}

const SongListCategoryView: React.FC<SongListCategoryViewProps> = ({ categories, songList, handleClick }) => {
  const displayedCategories = categories.filter(c => songList.find(s => s.category === c.id))

  return (
    <>
    <RaspuPromo />
      <Collapse
        ghost
        expandIcon={({ isActive }) =>
          <CaretRightFilled
            rotate={isActive ? 90 : 0}
            style={{ fontSize: '1.5em', color: '#DC143C', marginTop: 6 }}
          />
        }
        defaultActiveKey={songListSelectionHelper.getOpenCategories()}
        onChange={x => songListSelectionHelper.setOpenCategories(x)}
      >
        <Collapse.Panel
          header={<h2 className='songlist-category'>Yleistä</h2>}
          key={-1}
        >
          <About />
        </Collapse.Panel>

        {
          displayedCategories.map(category =>
            <Collapse.Panel
              header={<h2 className='songlist-category'>{category.name}</h2>}
              key={category.id}
            >
              <List size='small'>
                {songList.filter(song => song.category === category.id).map(song =>
                  <SongListRow
                    key={song.id}
                    number={song.id}
                    name={song.name}
                    handleClick={handleClick}
                  />
                )}
              </List>
            </Collapse.Panel>
          )
        }
      </Collapse>
    </>
  )
}

export default SongListCategoryView
