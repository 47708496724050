import React from 'react'
import { useHistory } from 'react-router-dom'
import { Category } from 'raspu-web-interface-models/Category'
import { SongListItem } from 'raspu-web-interface-models/SongListItem'
import SongListCategoryView from './SongListCategoryView'
import songListSelectionHelper from '../utils/songListSelectionHelper'
import scrollToSong from '../utils/scrollToSong'

interface SongListProps {
  readonly categories: Category[]
  readonly songList: SongListItem[]
  readonly searchEmpty: boolean
}

const SongList: React.FC<SongListProps> = ({ categories, songList, searchEmpty }) => {
  const history = useHistory()

  // Scroll to last selected song
  React.useEffect(() => {
    const lastSelected = songListSelectionHelper.getLastSelectedSong()
    if (lastSelected) {
      scrollToSong(lastSelected)
    }
  }, [])

  const handleSongClick = (songId: number) => {
    songListSelectionHelper.setLastSelectedSong(songId)
    history.push(`/${songId}`)
  }

  return (
    <div>
      {
        searchEmpty &&
        <SongListCategoryView
          categories={categories}
          songList={songList}
          handleClick={handleSongClick}
        />
      }
    </div>
  )
}

export default SongList
