import React from 'react'

const RasputinTitle: React.FC = () => (
  <div>
    <a href='/'>
      <p className='rasputin-title'>
        Rasputin
      </p>
    </a>
  </div>
)

export default RasputinTitle
