import React from 'react';
import { getTimerEndTime } from '../utils/getTimerValues';
import { ArrowRightOutlined } from '@ant-design/icons';

const RaspuCountdown: React.FC = () => {
  const difference = +new Date(getTimerEndTime()) - +new Date();
  

  return (
    <a href='/raspu13' className='new-raspu-container'>
      <p className='rasputin-font'>Raspu 13</p>
      {difference > 0 ? <p style={{ marginInline: '4px' }}>on tulossa!</p> : <p style={{ marginInline: '4px' }}>on täällä!</p>}
      <div className='arrow-box'>
        <ArrowRightOutlined />
      </div>
    </a>
    
  )
}

export default RaspuCountdown;
