export const getTimerEndTime = () => {
  const year = new Date().getFullYear();
  const endTime = `10/24/${year} 17:00:00`
  return endTime
}
export const calculateTimeLeft = (): { days: number; hours: number; minutes: number; seconds: number; isTimeUp: boolean } => {
  const difference = +new Date(getTimerEndTime()) - +new Date();

  if (difference <= 0) {
    return { days: 0, hours: 0, minutes: 0, seconds: 0, isTimeUp: true };
  }

  return {
    days: Math.floor(difference / (1000 * 60 * 60 * 24)),
    hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
    minutes: Math.floor((difference / 1000 / 60) % 60),
    seconds: Math.floor((difference / 1000) % 60),
    isTimeUp: false,
  };
};