import React from 'react'
import { CameraOutlined, CodeOutlined } from '@ant-design/icons'

const About: React.FC = () => {
  const TG_BOT_URL = 'https://t.me/raspu_bot'

  return (
    <div>
        <h3 className='rasputin-font'>
          Rasputin
        </h3>
        <p>
          Rasputin on <a href='https://www.perinneseura.fi'>Tampereen Teekkarien PerinneSeuran</a> tuottama ja ylläpitämä tamperelainen
          teekkarilaulukirja. Rasputin-laulukirjan fyysisiä painoksia on saatavilla hyvin
          varustelluista killoista tai PerinneSeuralta.
        </p>

        <h3>
          Mobiilisovellus
        </h3>
        <p>
          Asenna Rasputin-mobiilisovellus puhelimeesi avaamalla mobiiliselaimesi asetusvalikko
          ja valitsemalla:
        </p>
        <ul>
          <li>Lisää aloitusnäyttöön (Chrome)</li>
          <li>Lisää Koti-valikkoon (Safari)</li>
          <li>tai käyttämäsi mobiiliselaimen vastaava vaihtoehto.</li>
        </ul>

        <h3>
          Telegram
        </h3>
        <p>
          Rasputin on saatavilla myös Telegramissa! Rasputin Bot löytyy käyttäjätunnuksella
          {' '}
          <a href={TG_BOT_URL} target='_blank' rel='noreferrer noopener' style={{ fontWeight: 'bold' }}>
            @raspu_bot
          </a>
          .
        </p>

        <p style={{ color: 'lightgray', fontSize: 12, fontStyle: 'italic', marginBottom: -15 }}>
          <CodeOutlined /> Iiro Kiviluoma, Juho Kangas
          <CameraOutlined style={{ marginLeft: 10 }}/> Juho Viitala
        </p>
      </div>
  )
}

export default About
