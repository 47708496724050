import React from "react";
import YouTube from "react-youtube";

class RaspuMovie extends React.Component {
  state = {
    videoWidth: 0,
    videoHeight: 0
  };

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    const container = document.querySelector('.paper-content');
    if (container) {
      const containerWidth = container.clientWidth;
      const videoWidth = containerWidth;
      const videoHeight = (videoWidth / 16) * 9;

      this.setState({
        videoWidth,
        videoHeight
      });
    }
  };

  _onReady = (event: any) => {
    event.target.pauseVideo();
  };

  render() {
    const { videoWidth, videoHeight } = this.state;

    const options: any = {
      height: videoHeight,
      width: videoWidth,
      playerVars: {
        autoplay: 0,
        controls: 1,
      },
    };

    return (
      <div className="paper-content-center">
        <YouTube videoId="_d6CHAzsjGc" opts={options} onReady={this._onReady} id="video" />
      </div>
    );
  }
}

export default RaspuMovie;
